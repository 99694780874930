import {
  EmojiEvents,
  LoginOutlined,
  LogoutOutlined,
  RefreshOutlined,
  SaveOutlined,
  VerifiedUser,
} from "@mui/icons-material";
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import * as React from "react";
import { useShallow } from "zustand/react/shallow";
import { useAuthStore } from "../../state/authStore";
import { useExerciseStore } from "../../state/exerciseStore";
import { useModalStore } from "../../state/modalStore";

export const menuWidth = 250;

export const Menu = ({
  toggleDrawer,
}: {
  toggleDrawer: (
    open: boolean
  ) => (event: React.KeyboardEvent | React.MouseEvent) => void;
}) => {
  const [userName, isLoggedIn, logout] = useAuthStore(
    useShallow((state) => [state.name, !!state.token, state.logout])
  );
  const [pbSource, setPBSource, exerciseCompleted, reset] = useExerciseStore(
    useShallow((state) => [
      state.pbSource,
      state.setPBSource,
      !!state.exercises.filter((ex) => ex.completed).length,
      state.reset,
    ])
  );
  const [openLoginModal, openSaveSessionModal] = useModalStore(
    useShallow((state) => [state.openLoginModal, state.openSaveSessionModal])
  );

  const togglePbSource = () => {
    setPBSource(pbSource === "all-time" ? "season" : "all-time");
  };

  return (
    <Box
      sx={{ width: menuWidth }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        {isLoggedIn && (
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <VerifiedUser />
              </ListItemIcon>
              <ListItemText primary={userName || "Ingelogd"} />
            </ListItemButton>
          </ListItem>
        )}
        <ListItem disablePadding>
          {isLoggedIn ? (
            <ListItemButton onClick={logout}>
              <ListItemIcon>
                <LogoutOutlined />
              </ListItemIcon>
              <ListItemText primary={"Uitloggen"} />
            </ListItemButton>
          ) : (
            <ListItemButton onClick={openLoginModal}>
              <ListItemIcon>
                <LoginOutlined />
              </ListItemIcon>
              <ListItemText primary={"Inloggen"} />
            </ListItemButton>
          )}
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <EmojiEvents />
            </ListItemIcon>
            <ListItemText
              id="switch-list-label-pb"
              primary={"PR's"}
              secondary={
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="center"
                  component="span"
                >
                  <Typography component="span">Seizoen</Typography>
                  <Switch
                    checked={pbSource === "all-time"}
                    onChange={togglePbSource}
                  />
                  <Typography component="span">All-time</Typography>
                </Stack>
              }
            />
          </ListItemButton>
        </ListItem>
        {isLoggedIn && (
          <ListItem disablePadding>
            <ListItemButton
              onClick={openSaveSessionModal}
              disabled={!exerciseCompleted}
            >
              <ListItemIcon>
                <SaveOutlined />
              </ListItemIcon>
              <ListItemText primary={"Training opslaan"} />
            </ListItemButton>
          </ListItem>
        )}
        <ListItemButton onClick={reset}>
          <ListItemIcon>
            <RefreshOutlined />
          </ListItemIcon>
          <ListItemText primary={"Reset"} />
        </ListItemButton>
      </List>
    </Box>
  );
};
